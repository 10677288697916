import React, { useEffect, useState } from "react";
import Tab from "./Tab";
import HorizontalSliderV3 from "molecules/HorizontalSliderV3";

const TabsCapsuleV3 = (props) => {
    const { className, children, tabClass = '', middleContent, tabSpacing = 'pt-5 pb-2', isListingPage, width, consultationMode, isSelectedTab=""} = props;
    const [tabHeader, setTabHeader] = useState([]);
    const [childContent, setChildContent] = useState({});
    const [childProps, setChildProp] = useState([]);
    const [active, setActive] = useState(isSelectedTab);

    useEffect(() => {
        const headers = [];
        const childComponent = {};
        const props = {};
        React.Children.forEach(children, (element) => {
            if (!React.isValidElement(element)) return;
            const { name, tabKey } = element.props;
            headers.push({name, tabKey});
            childComponent[tabKey] = element.props.children;
            const { children, ...rest } = element.props;
            props[tabKey] = rest;
        });
        setTabHeader(headers);
        setChildProp(props)
        !active?.length && setActive(headers[0]?.tabKey);
        setChildContent({ ...childComponent });
    }, [props, children]);

    const changeTab = (name) => {
        setActive(name);
    };

    return (
        <div className={`${className} bg-basic-white md:rounded-2xl`}>
            <div className={`${tabClass} ${tabSpacing} bg-basic-white`}>
                <HorizontalSliderV3 className='w-auto' width={width} isListingPage={isListingPage} sliderList={tabHeader?.map((item, index) => (
                    <div key={item.tabKey} onClick={() => {
                        !childProps[item.key]?.disabled && changeTab(item.tabKey)
                        children[index]?.props?.onClick && children[index]?.props?.onClick()
                    }}
                        className={`${item.tabKey === active ? 'text-gray-900 border-primary1-500 text-[14px]' : 'text-gray-400 text-[14px]'} ${childProps[item.tabKey]?.disabled ? 'cursor-not-allowed  border-gray-400' : 'cursor-pointer'} font-semibold select-none border-b-2 px-5 py-2 whitespace-nowrap`} type='overline' >{item.name}</div>
                ))} />
            </div>
            {middleContent}
            <div className={`${isListingPage? 'md:min-w-[820px]':''}`}>
                {Object.keys(childContent).map((key) => {
                    if (key === active) {
                        return <div className="tab-child" key={key}>{childContent[key]}</div>;
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    );
};

TabsCapsuleV3.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName];

        let error = null;
        React.Children.forEach(prop, function (child) {
            if (child.type !== Tab) {
                error = new Error(
                    "`" + componentName + "` children should be of type `Tab.js`."
                );
            }
        });
        return error;
    }
};

export default TabsCapsuleV3;