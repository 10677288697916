import { Svg } from "atoms/SvgIcon";

const IconStar = ({ ...rest }) => {
    return <Svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M8 0L10.4922 4.56977L15.6085 5.52786L12.0325 9.31023L12.7023 14.4721L8 12.24L3.29772 14.4721L3.96752 9.31023L0.391548 5.52786L5.50779 4.56977L8 0Z" fill="#FFBD00"/>
    </Svg>
}


export default IconStar;


