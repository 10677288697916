import React, { useEffect, useState } from 'react'

import IconAngleLeft from 'atoms/SvgIcons/IconAngleLeft.svg';
import IconAngleRight from 'atoms/SvgIcons/IconAngleRight.svg';
import Carousel from 'atoms/Carousel';

const HorizontalSliderV3 = ({ className = '', sliderList, spaceBetween = 12, isShowArrow = true, isListingPage }) => {
    const [forSliderWidthIssue, setForSliderWidthIssue] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            setForSliderWidthIssue(<div className='w-0.5'></div>)
        }, 2000);
    }, [])

    const breakpoint = {
        0: {
            slidesPerView: 3,
            slidesPerGroup: 1,
        },
    }

    const listingBreakpoint = {
        0: {
            slidesPerView: 3,
            slidesPerGroup: 1,
        },
        820: {
            slidesPerView: 6,
            slidesPerGroup: 1,
        }
    }

    return (
        <div className={`${className} relative`}>
            <Carousel
                className=''
                breakpoints={isListingPage == true ? listingBreakpoint : breakpoint}
                spaceBetween={0}
                isShowArrow={isShowArrow}
                navigationElement='arrowH'
                sliderList={forSliderWidthIssue ? [...sliderList, forSliderWidthIssue] : sliderList}
                isSingleSlide={true}
                freeMode={{
                    enabled: true,
                    minimumVelocity: 0.1,
                }}
                speed={500}
            />

            {/* Arrows */}
            {isShowArrow && <>
                <div className='arrowHArrow--prev arrow-h-scroll group arrow-h-scroll--prev hover:border-primary1-500 !w-5 !h-5 !-left-3' data-automation='deal-left-arrow'>
                    <IconAngleLeft className='text-gray-500 group-hover:text-primary1-500'  width="100%" height="100%"/>
                </div>
                <div className='arrowHArrow--next arrow-h-scroll group arrow-h-scroll--next !w-5 !h-5 !-right-3' data-automation='deal-left-arrow'>
                    <IconAngleRight className='fill-gray-500 group-hover:fill-primary1-500' width="100%" height="100%" />
                </div>
               
            </>}
        </div>
    )
}

export default HorizontalSliderV3