import { Svg } from "atoms/SvgIcon";

const IconWallet = ({ ...rest }) => {
    return <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46009 7.30874C3.25611 7.51273 3.01244 7.75639 2.80845 7.96038C2.60216 8.16667 2.48633 8.44633 2.48633 8.738C2.48633 9.02967 2.60216 9.30934 2.80845 9.51563L3.46009 10.1673C3.66638 10.3736 3.94605 10.4894 4.23772 10.4894C4.52939 10.4894 4.80906 10.3736 5.01535 10.1673C5.21933 9.96328 5.463 9.71961 5.66699 9.51563C5.87328 9.30934 5.98911 9.02967 5.98911 8.738C5.98911 8.44633 5.87328 8.16667 5.66699 7.96038C5.463 7.75639 5.21933 7.51273 5.01535 7.30874C4.80906 7.10245 4.52939 6.9866 4.23772 6.9866C3.94605 6.9866 3.66638 7.10245 3.46009 7.30874ZM3.51547 8.66738L4.16711 8.01575C4.18557 7.99729 4.21095 7.98668 4.23772 7.98668C4.26403 7.98668 4.28941 7.99729 4.30833 8.01575L4.95997 8.66738C4.97843 8.68584 4.98904 8.7117 4.98904 8.738C4.98904 8.76431 4.97843 8.79016 4.95997 8.80862L4.30833 9.46025C4.28941 9.47871 4.26403 9.48932 4.23772 9.48932C4.21095 9.48932 4.18557 9.47871 4.16711 9.46025L3.51547 8.80862C3.49701 8.79016 3.4864 8.76431 3.4864 8.738C3.4864 8.7117 3.49701 8.68584 3.51547 8.66738Z" fill="#4B4B63" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.04127 13H4.04177C4.3179 13 4.54202 12.776 4.54202 12.5C4.54202 12.224 4.3179 12 4.04177 12H3.04127C2.76513 12 2.54102 12.224 2.54102 12.5C2.54102 12.776 2.76513 13 3.04127 13Z" fill="#4B4B63" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.04127 13H7.04177C7.3179 13 7.54202 12.776 7.54202 12.5C7.54202 12.224 7.3179 12 7.04177 12H6.04127C5.76513 12 5.54102 12.224 5.54102 12.5C5.54102 12.776 5.76513 13 6.04127 13Z" fill="#4B4B63" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.04127 13H10.0418C10.3179 13 10.542 12.776 10.542 12.5C10.542 12.224 10.3179 12 10.0418 12H9.04127C8.76513 12 8.54102 12.224 8.54102 12.5C8.54102 12.776 8.76513 13 9.04127 13Z" fill="#4B4B63" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0413 13H13.0418C13.3179 13 13.542 12.776 13.542 12.5C13.542 12.224 13.3179 12 13.0418 12H12.0413C11.7651 12 11.541 12.224 11.541 12.5C11.541 12.776 11.7651 13 12.0413 13Z" fill="#4B4B63" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6285 4.99998H1.6C1.3085 4.99998 1.0285 5.11598 0.821999 5.32198C0.615999 5.52848 0.5 5.80848 0.5 6.09998V13.9C0.5 14.1915 0.615999 14.4715 0.821999 14.678C1.0285 14.884 1.3085 15 1.6 15H14.4C14.6915 15 14.9715 14.884 15.178 14.678C15.384 14.4715 15.5 14.1915 15.5 13.9V6.09998C15.5 5.80848 15.384 5.52848 15.178 5.32198C14.9715 5.11598 14.6915 4.99998 14.4 4.99998H13.796L11.804 1.54998C11.6585 1.29748 11.418 1.11298 11.136 1.03748C10.8545 0.961982 10.554 1.00148 10.3015 1.14748L3.6285 4.99998ZM1.6 5.99998H14.4C14.4265 5.99998 14.452 6.01047 14.4705 6.02947C14.4895 6.04797 14.5 6.07348 14.5 6.09998V13.9C14.5 13.9265 14.4895 13.952 14.4705 13.9705C14.452 13.9895 14.4265 14 14.4 14H1.6C1.5735 14 1.548 13.9895 1.5295 13.9705C1.5105 13.952 1.5 13.9265 1.5 13.9V6.09998C1.5 6.07348 1.5105 6.04797 1.5295 6.02947C1.548 6.01047 1.5735 5.99998 1.6 5.99998ZM9.62849 4.99998H12.6415L11.888 3.69547L9.62849 4.99998ZM11.388 2.82947L10.938 2.04998C10.925 2.02698 10.903 2.01047 10.8775 2.00347C10.852 1.99647 10.8245 1.99998 10.8015 2.01348L5.6285 4.99998H7.6285L11.388 2.82947Z" fill="#4B4B63" />
    </Svg>


};

export default IconWallet;