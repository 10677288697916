import Button from 'atoms/Button'
import Tab from 'atoms/Tab';
import TabsCapsuleV3 from 'atoms/TabsCapsuleV3';
import Text from 'atoms/Text'
import { DOCTOR_CONSULTATION_MODE } from 'constant';
import useWindowSize from 'customHook/useWindowSize';
import React, { useEffect, useState } from 'react'

const DoctorSlots = ({className = '', slotList, showBookAppointmentFormModal, doctorName, consultationFee, selectedSlotTime, handleBookAppointment, isCurated, isListingPage= false, consultationMode = 0, setIsSelectedDate}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [tabs, setTabs] = useState(slotList);
  const [width, height] = useWindowSize();

  useEffect(()=>{
        setTabs(slotList);
    },[slotList])
   
    return (
        isCurated && slotList?.length > 0 && slotList[slotList?.length-1]?.totalAvailableSlots > 0 ? (<div className={`flex flex-row items-start  group min-h-[320px] border shadow-sm rounded-xl overflow-hidden pb-5 bg-basic-white`}>
            <div className={className}>
                <div className='flex justify-between px-2'>
                     <Text type='subtitle' fontWeight={600} className='font-Open-Sans line-clamp-1 text-primary1-900 text-base' >Select from Available Slots </Text>
                     <Text type='subtitle' fontWeight={600} className='font-Open-Sans line-clamp-1 text-primary1-900 text-base' >{consultationFee ? `₹${consultationFee} Fee`: 'FREE'}</Text>
                </div>
                <div className='h-auto'>
                    <TabsCapsuleV3 className=" bg-transparent-0" tabClass='bg-transparent-0 !py-2 px-2' isListingPage={isListingPage} consultationMode={consultationMode}>
                        {tabs && tabs.map((item) =>(item?.availableSlotCount > 0 && <Tab name={
                            <div onClick={()=> setIsSelectedDate(item?.visibleDate)}>
                                <div>{item?.visibleDate}</div>
                                {item?.availableSlotCount > 0 ?  <div className={`text-6xl text-primary2-600`}>{item?.availableSlotCount} Slots Available</div> :  <div className={`text-6xl text-gray-400`}>No Slots Available</div>}
                            </div>
                        } key={item?.visibleDate} tabKey={item?.visibleDate}>
                             <div className={`flex -mx-[2px] ${(item?.slots?.length > 8 || item?.slots?.length < 6) ? 'flex-row' : 'flex-col'} flex-wrap overflow-scroll min-h-[150px] max-h-[150px] text-center`}>
                            {item?.slots?.length > 0 ?
                            item?.slots?.map((slotsData)=>
                                <Button onClick={()=>{ if(slotsData?.isAvailable){ showBookAppointmentFormModal(slotsData?.value)}}} size='small' variant={!slotsData?.isAvailable ? 'DisableV2' :  slotsData?.value == selectedSlotTime ? 'Primary': 'Ghost'} className={`rounded-[4px] mx-[4.5px] md:mx-[7px] my-[5px] ${slotsData?.value == selectedSlotTime ? 'border-none' : ''} !border-[1px] h-[32px] px-2 sm:px-2 text-xs`}>{slotsData?.label}</Button>
                                ) : <div className='w-full'>No Slots Available</div> }
                            </div>
                        </Tab>))}             
                    </TabsCapsuleV3>
                </div>
                {isListingPage == false &&
                    <div className='border-t-2'>
                        <Button size='extraSmall_v2' disabled={selectedSlotTime || (width > 768 || selectedSlotTime) ? false : true} onClick={handleBookAppointment} className='mt-3 w-62.5 h-[40px] mx-auto  text-sm rounded-lg font-semibold'>
                            {`Book ${consultationMode == DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION ? "Clinic Visit" : "Video Consultation"}`}
                        </Button>
                    </div>
                }
            </div>
        </div>) : 
        <div className={`flex flex-row items-start group min-h-[80px] border shadow-sm rounded-xl overflow-hidden bg-primary1-100 p-4 text-primary1-900 text-base font-semibold`}>
            {doctorName} is not available for {`${consultationMode == DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION ? "in-clinic consultation" : "online consultation"}`} at the moment.
        </div>
    )
}

export default DoctorSlots