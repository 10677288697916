import { Svg } from "atoms/SvgIcon";

const IconAddMeeting = ({rest }) => {

    return <Svg  viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}  width="100%" height="100%">
        <circle cx="10.1641" cy="10" r="9.66667" fill="#006593" stroke="white" stroke-width="0.666667" />
        <g clip-path="url(#clip0_14183_41540)">
            <path d="M12.4263 11.4509C12.4263 11.6179 12.4263 11.7613 12.4263 11.9047C12.4242 12.6277 11.8956 13.164 11.1494 13.166C9.48221 13.1738 7.815 13.1738 6.14778 13.166C5.4077 13.164 4.875 12.6277 4.875 11.9145C4.875 10.6395 4.875 9.36445 4.875 8.08941C4.875 7.37232 5.40973 6.83795 6.14778 6.83402C7.815 6.82616 9.48221 6.82616 11.1494 6.83402C11.8936 6.83795 12.4242 7.37232 12.4263 8.0953C12.4263 8.23872 12.4263 8.38214 12.4263 8.54324C12.469 8.51573 12.4974 8.50001 12.5259 8.48037C13.0972 8.08352 13.6686 7.6847 14.2379 7.28588C14.492 7.10907 14.7624 7.08353 15.0369 7.21712C15.3093 7.35071 15.4456 7.58057 15.4456 7.87527C15.4476 9.29176 15.4496 10.7082 15.4456 12.1228C15.4456 12.6316 14.9556 12.9793 14.4635 12.8281C14.3944 12.8065 14.3273 12.7691 14.2684 12.7279C13.7031 12.3369 13.1379 11.944 12.5727 11.5511C12.532 11.5216 12.4893 11.4941 12.4263 11.4509ZM8.63844 12.6788C9.45578 12.6788 10.2752 12.667 11.0925 12.6827C11.5703 12.6925 11.9302 12.3605 11.9281 11.8792C11.92 10.6257 11.9261 9.37427 11.9241 8.12084C11.9241 7.6238 11.615 7.32124 11.1047 7.32124C9.46798 7.32124 7.8333 7.32124 6.19658 7.32124C6.11931 7.32124 6.03799 7.32124 5.96276 7.34089C5.60085 7.4234 5.3772 7.7122 5.3772 8.09727C5.3772 9.35659 5.3833 10.6179 5.37313 11.8772C5.36907 12.3566 5.72894 12.6905 6.20877 12.6807C7.01799 12.665 7.82923 12.6768 8.63844 12.6768V12.6788ZM14.9454 10.001C14.9454 9.59824 14.9454 9.19352 14.9454 8.79078C14.9454 8.49019 14.9454 8.18961 14.9454 7.88902C14.9454 7.78293 14.9047 7.69452 14.801 7.64737C14.7075 7.60415 14.6201 7.6179 14.5347 7.67684C13.8536 8.15424 13.1704 8.62968 12.4913 9.10905C12.4568 9.13262 12.4283 9.18763 12.4283 9.22889C12.4242 9.74362 12.4242 10.2564 12.4283 10.7711C12.4283 10.8124 12.4547 10.8693 12.4873 10.891C13.1765 11.3762 13.8698 11.8575 14.5632 12.3389C14.6913 12.4292 14.8742 12.3664 14.923 12.2171C14.9413 12.164 14.9434 12.1051 14.9434 12.0481C14.9434 11.3664 14.9434 10.6847 14.9434 10.0049L14.9454 10.001Z" fill="#FCFCFC" />
        </g>
        <defs>
            <clipPath id="clip0_14183_41540">
                <rect width="11.6667" height="11.6667" fill="white" transform="translate(4.33105 4.16675)" />
            </clipPath>
        </defs>
    </Svg>

}

export default IconAddMeeting;


