import { Svg } from "atoms/SvgIcon";

const IconWalletCLinic = ({ ...rest }) => {
    return <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5312 4.71882H14.9269L14.0782 1.32388C14.0139 1.06698 13.7496 0.914226 13.4949 0.986788L0.336437 4.73807C0.134344 4.79748 0 4.98341 0 5.18757V14.5626C0 14.8214 0.209875 15.0313 0.46875 15.0313H15.5312C15.7901 15.0313 16 14.8214 16 14.5626V5.18757C16 4.92866 15.7901 4.71882 15.5312 4.71882ZM13.9606 4.71882H3.82294L13.2862 2.02113L13.9606 4.71882ZM15.0625 14.0938H0.9375V5.65632H15.0625V14.0938Z" fill="#4B4B63" />
        <path d="M10.8137 7.53131H11.9708C12.133 8.10291 12.5843 8.55412 13.1559 8.71634V11.0337C12.5843 11.196 12.133 11.6472 11.9708 12.2188H10.8105C10.5517 12.2188 10.3418 12.4287 10.3418 12.6875C10.3418 12.9464 10.5517 13.1563 10.8105 13.1563H12.3746C12.6335 13.1563 12.8434 12.9464 12.8434 12.6875C12.8434 12.2567 13.1938 11.9063 13.6246 11.9063C13.8835 11.9063 14.0934 11.6964 14.0934 11.4375V8.31253C14.0934 8.05366 13.8835 7.84378 13.6246 7.84378C13.1938 7.84378 12.8434 7.49331 12.8434 7.06253C12.8434 6.80366 12.6335 6.59378 12.3746 6.59378H10.8137C10.5548 6.59378 10.3449 6.80366 10.3449 7.06253C10.3449 7.32141 10.5548 7.53131 10.8137 7.53131Z" fill="#4B4B63" />
        <path d="M2.375 11.9063C2.80578 11.9063 3.15625 12.2568 3.15625 12.6876C3.15625 12.9465 3.36612 13.1563 3.625 13.1563H5.18594C5.44481 13.1563 5.65469 12.9465 5.65469 12.6876C5.65469 12.4287 5.44481 12.2188 5.18594 12.2188H4.02878C3.86656 11.6472 3.41534 11.196 2.84375 11.0338V8.71639C3.41534 8.55417 3.86656 8.10295 4.02878 7.53136H5.18594C5.44481 7.53136 5.65469 7.32148 5.65469 7.06261C5.65469 6.80373 5.44481 6.59386 5.18594 6.59386H3.625C3.36612 6.59386 3.15625 6.80373 3.15625 7.06261C3.15625 7.49339 2.80578 7.84386 2.375 7.84386C2.11612 7.84386 1.90625 8.05373 1.90625 8.31261V11.4376C1.90625 11.6965 2.11612 11.9063 2.375 11.9063Z" fill="#4B4B63" />
        <path d="M7.99922 12.2173C9.29113 12.2173 10.3422 11.1662 10.3422 9.87429C10.3422 8.58239 9.29113 7.53133 7.99922 7.53133C6.70731 7.53133 5.65625 8.58236 5.65625 9.87429C5.65625 11.1662 6.70731 12.2173 7.99922 12.2173ZM7.99922 8.46883C8.77419 8.46883 9.40469 9.09933 9.40469 9.87429C9.40469 10.6493 8.77419 11.2798 7.99922 11.2798C7.22425 11.2798 6.59375 10.6493 6.59375 9.87429C6.59375 9.09933 7.22425 8.46883 7.99922 8.46883Z" fill="#4B4B63" />
    </Svg>


};

export default IconWalletCLinic;