import React from "react";
import PropTypes from "prop-types";

const tabType = {
    v2: 'bg-basic-white w-full overflow-y-auto',
}

const Tab = (props) => {
    return <div className={`${tabType[props.type] || ''} tab-pane`} {...props}>{props.childern}</div>;
};
Tab.propTypes = {
    name: PropTypes.string
};

export default Tab;