import { Svg } from "atoms/SvgIcon";

const IconVideo = ({ iconColor = "#FCFCFC", fill = "", ...rest }) => {
    return <Svg viewBox='0 0 36 26' type='IconVideo' {...rest} >
        <path
            d="M33.6233 1.62028C32.789 1.09543 31.8294 1.09543 31.0368 1.66799L25.5718 5.34195V4.62624C25.5718 2.62227 24.1534 1 22.4012 1H4.17055C2.4184 1 1 2.62227 1 4.62624V21.3738C1 23.3777 2.4184 25 4.17055 25H22.4429C24.1951 25 25.6135 23.3777 25.6135 21.3738V20.5149L31.1202 24.1889C31.5374 24.4752 31.9963 24.6183 32.4135 24.6183C32.8724 24.6183 33.2896 24.4751 33.7067 24.2366C34.5411 23.7117 35 22.7575 35 21.66L34.9583 4.19682C34.9583 3.14712 34.4577 2.19284 33.6233 1.62028ZM23.9448 21.3738C23.9448 22.328 23.2773 23.0915 22.4429 23.0915H4.17055C3.3362 23.0915 2.66871 22.328 2.66871 21.3738V4.67396C2.66871 3.71968 3.3362 2.95626 4.17055 2.95626H22.4429C23.2773 2.95626 23.9448 3.71968 23.9448 4.67396V21.3738ZM32.8724 22.5666C32.7472 22.662 32.3718 22.8529 31.9546 22.5666L25.6135 18.3201V7.58449L31.9129 3.29026C32.3301 3.00398 32.7055 3.19483 32.8307 3.29026C32.9558 3.38569 33.2896 3.67197 33.2896 4.19682L33.3313 21.66C33.3313 22.2326 32.9975 22.4712 32.8724 22.5666Z"
            stroke={iconColor}
            fill={fill}
            strokeWidth="0.5"
        />
    </Svg>
};

export default IconVideo;