import Card from "atoms/Card";
import ImageNext from "atoms/ImageNext";
import LinkAnchor from "atoms/LinkAnchor";
import Text from "atoms/Text";
import IconAddMeeting from "atoms/SvgIcons/HompagePatientIcons/IconAddMeeting.svg";
import IconVideo from "atoms/SvgIcons/IconVideo.svg";
import IconStar from "atoms/SvgIcons/HompagePatientIcons/IconStar.svg";
import { getAuth } from "services/identity.service";
const moment = require('moment');
import appointment_flow from "../assets/mo_engage/appointment_flow.json"
import { captureEvent } from 'services/analytics.service';
import IconLocation from "atoms/SvgIcons/IconLocation.svg";
import IconGraph from "atoms/SvgIcons/IconGraph.svg";
import IconDot from "atoms/SvgIcons/IconDot.svg";
import useWindowSize from "customHook/useWindowSize";
import IconWallet from "atoms/SvgIcons/IconWallet.svg";
import DoctorSlots from "./DoctorSlots";
import { useState } from "react";
import { getDoctorAppointmentSlots } from "services/user.service";
import MakeAppointment from "@/components/BookAnAppointment/MakeAppointment";
import { APPOINTMENT_TYPE, DOCTOR_CONSULTATION_MODE, USER_TYPES } from "constant";

import { useRouter } from "next/router";
import Button from "atoms/Button";
import IconWalletCLinic from "atoms/SvgIcons/IconWalletClinic.svg";
import { useUserContext } from "@/components/context/UserContext";

const DoctorsSummeryCardV2 = ({ className, image, fullName, city, degree, experience, specializations, buttonText, handleAppointment, linkHref, consultationFee, rating, isShowVideoIcon = false, nextAvailableSlots = [], isCurated = false, pageName = '', doctorId = null, isShowVerifiedIcon = false, screen, isListingPage = false, doctorAbout = '', profileViews, recentlyConsulted, availableInClinic, clinicId = null, doctor, clinicFee, isDoctorDetail }) => {
    const auth = getAuth();
    const isConsultationAllowed = auth?.user?.userType == 1 || auth?.user?.userType == undefined;
    const eventCategory = pageName;
    const [selectedSlotTime, setSelectedSlotTime] = useState(null)
    const [showBookAppointmentModal, setBookAppointmentModal] = useState(false);

    const eventName = appointment_flow?.entity?.Request_Appointment_Click?.event_name;
    const [width] = useWindowSize();
    const [doctorSlots, setDoctorSlots] = useState(null)
    const [isSlotLoad, setIsSlotLoad] = useState(true)
    const [consultationMode, setConsultationMode] = useState(DOCTOR_CONSULTATION_MODE?.VIDEO_CONSULTATION);
    const [isSlotSelected, setIsSlotSelected] = useState(false)

    const specializationArray = typeof specializations === 'string' ? specializations.split(',') : specializations;
    const [expandedDoctorId, setExpandedDoctorId] = useState(null);
    const router = useRouter();
    const { setConsultationType } = useUserContext();

    async function getDoctorSlots({ doctorId, type }) {
        setIsSlotLoad(true);
        const slotResp = await getDoctorAppointmentSlots({
            doctorId,
            type,
            ...(type == 1 ? { clinicId: clinicId } : {}),
        });
        if (slotResp?.status) {
            setDoctorSlots(slotResp?.entity)
            setIsSlotLoad(false)
        }
    }

    const handleCardExpansion = async ({ doctorId, type }) => {
        setExpandedDoctorId(expandedDoctorId === doctorId ? null : doctorId);
        await getDoctorSlots({ doctorId, type })
        setConsultationMode(type)
    };

    const showBookAppointmentFormModal = async (time) => {
        setSelectedSlotTime(time);
        setBookAppointmentModal(true);
        setIsSlotSelected(false);
    }
    const handleBookAppointment = (e) => {
        if (selectedSlotTime) {
            setBookAppointmentModal(true);
            setIsSlotSelected(false);
        } else {
            setIsSlotSelected(true);
        }
    }

    return (
        <Card className={`${className} ${screen == "doctorListing" ? 'p-4' : 'p-3'} cursor-pointer hover:shadow-extraLight transition-all duration-300 card--regular relative flex flex-col rounded-md shadow-sm`}>
            <div className='flex-grow min-h-[240px]'>
                <div className="flex flex-col w-full" >
                    <div className="relative flex items-center justify-between w-full">
                        <div className="flex items-start w-full" onClick={() => { router.push(linkHref) }}>
                            <div className="flex flex-col items-center" >
                                <div className={`relative ${screen == "doctorListing" ? 'min-w-[120px] h-[120px]' : 'min-w-[80px] h-20'} rounded-full bg-gray-100`}>
                                    <ImageNext
                                        className="w-full h-full object-cover rounded-full"
                                        src={image}
                                        alt={fullName}
                                        layout="fill"
                                        defaultImageFor="avatar"
                                    />
                                    {isConsultationAllowed && isCurated && nextAvailableSlots?.length > 0 && isShowVideoIcon ? (
                                        <div className="absolute bottom-1 right-1 flex items-center justify-center h-7 w-7 rounded-full bg-blue-500 text-white overflow-x-visible">
                                            <IconAddMeeting className="h-7" />
                                        </div>
                                    ) : null}
                                </div>
                                {(screen === "doctorListing" && profileViews != 0) &&
                                    <div className="flex items-center space-x-1 mt-2.5">
                                        <IconGraph />
                                        <Text className="text-xs text-gray-600 font-semibold">{profileViews} Views</Text>
                                    </div>
                                }
                            </div>
                            <div className="ml-4 flex-grow w-full">
                                <div className="flex justify-between">
                                    <Text fontWeight={600} className={`font-Open-Sans font-semibold text-gray-900 line-clamp-1 ${screen == "doctorListing" ? 'pb-1 text-lg' : 'text-14-16'}`}>{fullName || ""}</Text>
                                    {isConsultationAllowed && (screen == "doctorListing" && width > 640 || screen != "doctorListing") ?
                                        <div className="flex justify-end mr-0">
                                            <div className="flex gap-1">
                                                <IconStar className="col-end-5 col-span-1 text-yellow-400 w-4 h-4" />
                                                <Text className="col-end-7 col-span-2 text-14-16 font-Open-Sans font-semibold text-gray-900">{((!rating || rating < 1)) ? 'New' : (rating) + '/5'}</Text>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                                {(width < 640 && screen == "doctorListing" && isConsultationAllowed) ?
                                    <div className="pb-1">
                                        <div className="flex gap-1">
                                            <IconStar className="text-yellow-400 w-4 h-4" />
                                            <Text className="text-14-16 font-Open-Sans font-semibold text-gray-900">{((!rating || rating < 1)) ? 'New' : (rating) + '/5'}</Text>
                                        </div>
                                    </div> : null
                                }

                                <Text fontWeight={400} className={`max-w-170 text-gray-600 ${(screen != "doctorListing") ? 'line-clamp-1' : ''}  ${screen == "doctorListing" ? 'pb-1 text-sm' : 'text-12-16'}`}>
                                    {Array.isArray(specializations) ? specializations.join(', ') : specializations}
                                </Text>

                                <div className={`${(screen == "doctorListing" && width > 640) ? 'pb-1.5' : 'pb-1'} flex items-center gap-2`}>
                                    <Text className={`text-gray-600 ${screen == "doctorListing" ? 'text-sm pr-1.5' : 'text-12-16'}`}>
                                        {experience || ""}
                                    </Text>
                                    {(screen == "doctorListing" && width > 640) && (
                                        <div className="flex items-center">
                                            <IconDot />
                                            <Text className={`text-sm pl-1.5 max-w-170 text-gray-600 line-clamp-1`}>
                                                {degree || "degree"}
                                            </Text>
                                        </div>
                                    )}

                                </div>
                                {
                                    (screen == "doctorListing" && width < 640) ?
                                        <Text className={`text-sm max-w-170 text-gray-600 line-clamp-1 ${width > 640 ? 'pb-2.5' : 'pb-1.5'}`}>
                                            {degree || "degree"}
                                        </Text> :

                                        screen !== "doctorListing" && <Text className={`text-sm  max-w-170 text-gray-600 line-clamp-1`}>
                                            {degree || "degree"}
                                        </Text>
                                }
                                {
                                    <Text className={`flex items-center ${width > 640 ? 'pb-1' : 'pb-3.5'} gap-1 text-xs text-gray-900`}>
                                        <img className='w-4 h-4' src="/images/icon_success.svg" alt='verified_tick' />
                                        Verified Doctor
                                    </Text>
                                }

                                {(width > 640) &&
                                    <>
                                        <div className={`flex gap-1 items-center ${screen == "doctorListing" && 'pb-1'}`}>
                                            <IconLocation className='text-gray-500' size={16} />
                                            <Text className={`${screen == "doctorListing" ? 'text-sm' : 'text-12-16'} text-gray-900 font-Open-Sans line-clamp-1`}>
                                                {city?.split(', ')?.map(part => part.toLowerCase()?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ')).join(', ') || ""}</Text>
                                        </div>

                                        {
                                            ( isCurated==1 || availableInClinic==1) && isConsultationAllowed && (isListingPage == true || isDoctorDetail==true) &&
                                            <>
                                                <div className="flex items-center pb-1">
                                                    <Text className='text-sm text-gray-900 mr-3'>
                                                        Consultation Fee
                                                    </Text>
                                                    {
                                                        availableInClinic==1  &&
                                                        <>
                                                            <IconWalletCLinic />
                                                            <Text className='font-semibold mr-1 ml-2 text-xs'>
                                                                {clinicFee ? `₹${clinicFee}` : 'FREE at clinic'}
                                                                {clinicFee ? '  at clinic' : ''}
                                                            </Text>
                                                            <div className="pr-4"></div>

                                                        </>
                                                    }
                                                    {
                                                        isCurated==1 &&
                                                        <>
                                                            <IconWallet />
                                                            <Text className='font-semibold mr-1 ml-1.5 text-xs'>
                                                                {consultationFee ? `₹${consultationFee}` : 'FREE online'}
                                                                {consultationFee ? '  online' : ''}
                                                            </Text>
                                                        </>
                                                    }
                                                </div>

                                                {recentlyConsulted &&
                                                    <div className="pb-1">
                                                        <Text className='text-sm font-semibold text-gray-900 font-Open-Sans'>Recently consulted for : <span className="font-normal">{recentlyConsulted}</span></Text>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {screen == 'doctorListing' && isConsultationAllowed && (isCurated == 0) && <Text className='text-primary1-900 text-xs'>{`${fullName} is not available for online consultation at the moment.`}</Text>}
                                        {(![USER_TYPES?.DOCTORS, USER_TYPES?.DOCTOR_DISTRIBUTOR, USER_TYPES?.STUDENTS].includes(auth?.user?.userType) && width > 768 && doctorAbout) ? <Text className="mt-1 text-gray-600 text-sm whitespace-pre-line" fontWeight={400} type="bodySmall" >
                                            {doctorAbout}
                                        </Text> : null}
                                    </>
                                }

                                {
                                    (screen == "doctorListing" && width > 640 && isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1) &&
                                    <div className="text-gray-600 flex gap-3 items-center">
                                        <Text type="label" className="text-sm font-Open-Sans text-semantic-success_base">Next available at</Text>
                                        <div className="flex gap-6">
                                            <div className="flex items-center">
                                                <IconVideo className="w-3 h-3 mr-2" />
                                                {nextAvailableSlots[0]?.label && <Text className='text-sm text-gray-600 font-Open-Sans'>{nextAvailableSlots[0]?.label}, {moment(nextAvailableSlots[0].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>}
                                            </div>
                                            {nextAvailableSlots[1]?.label && <div className="flex items-center mr-2">
                                                <IconVideo className="w-3 h-3 mr-2" />
                                                <Text className='text-sm text-gray-600 font-Open-Sans'>{nextAvailableSlots[1]?.label}, {moment(nextAvailableSlots[1].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>
                                            </div>}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        (screen == 'doctorListing' && width < 640) &&
                        <div className="border-t border-gray-200">
                            <div className={`flex gap-1 items-center py-2.5`}>
                                <IconLocation className='text-gray-500' size={16} />
                                <Text className={`text-sm text-gray-900 font-Open-Sans line-clamp-1`}>
                                    {city?.split(', ')?.map(part => part.toLowerCase()?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ')).join(', ') || ""}</Text>
                            </div>

                            <div className="flex items-center pb-3">
                                <Text className='text-sm text-gray-900 mr-3'>
                                    Consultation Fee
                                </Text>
                                <IconWallet />
                                <Text className='font-semibold mr-1 ml-1.5 text-sm'>
                                    {consultationFee ? `₹${consultationFee}` : 'FREE Consultation'}
                                </Text>
                                {consultationFee ? 'online' : ''}
                            </div>

                            <div className="">
                                <Text className='text-sm font-semibold text-gray-900 font-Open-Sans'>Recently consulted for : <span className="font-normal">{recentlyConsulted}</span></Text>
                            </div>
                            {screen != "doctorListing" && isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 &&
                                <div className="text-gray-600 mt-2 border-t border-gray-200 py-2">
                                    <Text type="label" className="text-sm font-Open-Sans text-primary2-800">Next available at</Text>
                                    <div className="flex justify-between">
                                        <div className="flex items-center">
                                            <IconVideo className="w-3 h-3 mr-2" />
                                            {nextAvailableSlots[0]?.label && <Text className='text-12-16 text-gray-600 font-Open-Sans'>{nextAvailableSlots[0]?.label}, {moment(nextAvailableSlots[0].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>}
                                        </div>
                                        {nextAvailableSlots[1]?.label && <div className="flex items-center mr-2">
                                            <IconVideo className="w-3 h-3 mr-2" />
                                            <Text className='text-12-16 text-gray-600 font-Open-Sans'>{nextAvailableSlots[1]?.label}, {moment(nextAvailableSlots[1].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>
                                        </div>}
                                    </div>
                                </div>
                            }
                            {
                                (screen == 'doctorListing' && !isDoctorDetail && isConsultationAllowed && (isCurated == 0 || nextAvailableSlots?.length == 0)) && <Text className='w-3/4 text-center mx-auto pt-2 text-primary1-900 text-xs'>{`${fullName} is not available for online consultation at the moment.`}</Text>
                            }
                        </div>
                    }

                    <div>
                        {
                            screen != "doctorListing" &&
                            <div className="flex justify-between items-center border border-y-1 border-x-0 mt-2 pt-1 pb-1">
                                {(isCurated == 1 || availableInClinic == 1) && isConsultationAllowed &&
                                    <div className="flex items-center pb-1">
                                        <Text className='text-xs md:text-sm text-gray-900 mr-1 md:mr-3'>
                                            Consultation Fee
                                        </Text>
                                        {
                                            availableInClinic==1 &&
                                            <div className="flex">
                                                <IconWalletCLinic />
                                                <Text className={`font-semibold mx-1 md:mr-1 md:ml-2 text-xs ${width < 430 && 'text-[10px]'}`}>
                                                    {clinicFee ? `₹${clinicFee}` : 'FREE at clinic'}
                                                    {clinicFee ? '  at clinic' : ''}
                                                </Text>
                                                {/* <div className="pr-4"></div> */}

                                            </div>
                                        }
                                        {
                                            isCurated==1 &&
                                            <div className="flex">
                                                <IconWallet />
                                                <Text className={`font-semibold mx-1 md:mr-1 md:ml-2 text-xs ${width < 430 && 'text-[10px]'}`}>
                                                    {consultationFee ? `₹${consultationFee}` : 'FREE online'}
                                                    {consultationFee ? '  online' : ''}
                                                </Text>
                                            </div>
                                        }
                                    </div>}
                            </div>





                        }
                        {screen != "doctorListing" && isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 &&
                            <div className="text-gray-600 mt-2">
                                <Text type="label" className="text-10-14 font-Open-Sans text-primary2-800">NEXT AVAILABLE AT</Text>
                                <div className={`flex ${width < 430 && 'flex-wrap'} justify-between`}>
                                    <div className="flex items-center">
                                        <IconVideo className="w-3 h-3 mr-2" />
                                        {nextAvailableSlots[0]?.label && <Text className='text-12-16 text-gray-600 font-Open-Sans'>{nextAvailableSlots[0]?.label}, {moment(nextAvailableSlots[0].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>}
                                    </div>
                                    {nextAvailableSlots[1]?.label && <div className="flex items-center mr-2">
                                        <IconVideo className="w-3 h-3 mr-2" />
                                        <Text className='text-12-16 text-gray-600 font-Open-Sans'>{nextAvailableSlots[1]?.label}, {moment(nextAvailableSlots[1].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>
                                    </div>}
                                </div>
                            </div>}
                        {
                            (screen != 'doctorListing' && isConsultationAllowed && (isCurated == 0 || nextAvailableSlots?.length == 0)) && <Text className='w-3/4 text-center mx-auto py-6 text-primary1-900 text-xs'>{`${fullName} is not available for online consultation at the moment.`}</Text>
                        }
                    </div>

                    {isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 && (
                        <div className={`flex ${(screen == "doctorListing" && width > 640) ? 'pl-34' : 'justify-center'} ${width < 430 && 'flex-wrap'} mt-3 mb-1 gap-1 md:gap-2 w-full`}>
                            {availableInClinic == true &&
                                <Button
                                    size='extraSmall_v4'
                                    variant="Ghost"
                                    fontWeight={400}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isListingPage == true) {
                                            handleCardExpansion({ doctorId, type: DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION });
                                        }
                                        else {
                                            setConsultationType(1);
                                            router.push(linkHref)
                                        }
                                    }}
                                    fontSize={"bodySmall"}
                                    className={`min-w-[170px] md:min-w-[182px] h-9 ${isListingPage == true ? 'text-sm' : 'text-xs'} font-semibold  button button-Primary rounded-sm-0.5`}
                                >
                                    {buttonText || "Book Clinic Visit"}
                                </Button>
                            }
                            <Button
                                size='extraSmall_v4'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    captureEvent(eventCategory, eventName, '', { doctor_id: doctorId, doctor_name: fullName, action: 'Click' });
                                    if (isListingPage == true) {
                                        handleCardExpansion({ doctorId, type: DOCTOR_CONSULTATION_MODE?.VIDEO_CONSULTATION });
                                    }
                                    else {
                                        setConsultationType(0);
                                        router.push(linkHref)
                                    }
                                }}
                                className={`min-w-[170px] md:min-w-[182px] h-9 ${isListingPage == true ? 'text-sm' : 'text-xs'} font-semibold  button button-Primary rounded-sm-0.5`}
                            >
                                {availableInClinic == 1 ? "Video Consultation" : "Book Video Consultation"}
                            </Button>
                        </div>
                    )}

                    {expandedDoctorId === doctorId && isSlotLoad == false && (
                        <div className="md:max-w-full max-w-[380px] mx-auto">
                            <DoctorSlots
                                className={`${consultationMode == 0 ? 'bg-primary1-100' : 'bg-other-green-900'} w-full  h-12 p-3 rounded-tl-xl rounded-tr-xl`}
                                slotList={doctorSlots}
                                showBookAppointmentFormModal={showBookAppointmentFormModal}
                                doctorName={fullName}
                                consultationFee={consultationMode == DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION ? clinicFee : doctor?.consultationFee}
                                selectedSlotTime={selectedSlotTime}
                                handleBookAppointment={handleBookAppointment}
                                isCurated={isCurated}
                                isListingPage={true}
                            />
                        </div>
                    )}

                    {showBookAppointmentModal &&
                        <div className="slotWrapper">
                            <MakeAppointment
                                className="slotWrapper"
                                isModalOpen={showBookAppointmentModal}
                                setIsModalOpen={setBookAppointmentModal}
                                section={'doctorpage'}
                                buttonId={'makeAppointment'}
                                doctorId={doctorId}
                                isCoverContent={false}
                                isFrontForm={false}
                                selectedSlotTime={selectedSlotTime}
                                doctor={doctor}
                                consultationFee={consultationMode == DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION ? clinicFee : doctor?.consultationFee}
                                clinicId={consultationMode == DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION ? clinicId : null}
                                appointmentType={consultationMode == DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION ? APPOINTMENT_TYPE?.IN_CLINIC_APPOINTMENT : APPOINTMENT_TYPE?.ONLINE_APPOINTMENT}
                            />
                        </div>
                    }
                </div>
            </div>
        </Card >
    )
};

export default DoctorsSummeryCardV2;
